import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { isArray } from "lodash";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import { useDispatch } from "react-redux";

import useGatAdminConfigData from "../../../../../controllers/use-gat-admin-config-data";
import { deleteTimeOffType } from "../../../../../store/gat-admin-slice";
import LocationFilters from "../ui/table/location-filters";
interface GridColumn {
	field: string;
	type: string;
	minWidth?: number;
	width?: number;
	flex?: number;
	headerName?: string;
	renderHeader?: () => React.ReactNode;
	renderCell?: (params: { row: any }) => React.ReactNode;
	valueGetter?: (params: { row: any }) => any;
	headerAlign?: "left" | "center" | "right";
	sortable?: boolean;
	getActions?: (params: { row: any }) => any[];
	cellClassName?: string;
	headerClassName?: string;
}
const useGatAttendanceColumns = (handleOpenEdit: any) => {
	const { locations } = useGatAdminConfigData();
	const confirm = useConfirm();
	const dispatch = useDispatch();

	const deleteContent = () => (
		<Box>
			<Typography
				sx={{
					fontFamily: "Roboto",
					fontSize: "14px",
					fontWeight: 400,
					lineHeight: "28px",
					color: "#000000",
				}}
			>
				Current balances for all existing employees will be lapsed and they will
				no longer be allowed to apply for Time Off of this type.
			</Typography>
			<Typography
				sx={{
					fontFamily: "Roboto",
					fontSize: "14px",
					fontWeight: 400,
					lineHeight: "28px",
				}}
			>
				Are you sure you want to deactivate?
			</Typography>
		</Box>
	);

	const handleDelete = (id: number, name: string) => {
		confirm({
			title: "Deactivate Time Off Type",
			content: deleteContent(),

			cancellationText: "Cancel",
			confirmationText: (
				<Button variant='contained' color='error'>
					Delete
				</Button>
			),
		})
			.then(() => dispatch(deleteTimeOffType(id)))
			.catch(() => console.log("Deletion cancelled."));
	};

	const columns: GridColumn[] = [
		{
			field: "timeOffType",
			type: "string",
			minWidth: 155,
			flex: 1,
			headerName: "Time-off type",
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Time Off Type"}
				</span>
			),
			renderCell: (params) => (
				<Box width={"95%"}>
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						{params.row.name}
					</Typography>
				</Box>
			),
			valueGetter: (params) => params.row.name,
		},
		{
			field: "description",
			type: "string",
			minWidth: 155,
			flex: 1,
			headerName: "Description",
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Description"}
				</span>
			),
			renderCell: (params) => (
				<Box width='95%'>
					<Typography
						noWrap
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontFamily: "Roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						{params.row.description}
					</Typography>
				</Box>
			),
			valueGetter: (params) => params.row.description,
		},

		{
			field: "advanceNotice",
			type: "string",
			minWidth: 140,
			flex: 1,
			headerName: "Advance Notice",
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Advance Notice"}
				</span>
			),
			renderCell: (params) => {
				const count = params.row.applyBeforeHours ?? 0;
				const isHours = params.row.advanceNoticeMetric === "Hours";
				const metric = isHours ? "hour" : "day";
				const metricLabel = count > 1 ? `${metric}s` : metric;

				return (
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						{count} {metricLabel}
					</Typography>
				);
			},
			valueGetter: (params) => Number(params.row.applyBeforeHours),
		},
		{
			field: "increment",
			type: "string",
			minWidth: 140,
			flex: 1,
			headerName: "increment",
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Time Off Increment"}
				</span>
			),
			renderCell: (params) => {
				const count = params.row.incrementHours ?? 0;
				const metricLabel = count > 1 ? "hours" : "hour";
				return (
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						{count} {metricLabel}
					</Typography>
				);
			},
			valueGetter: (params) => Number(params.row.incrementHours),
		},
		// {
		// 	field: "annualBalance",
		// 	type: "string",
		// 	minWidth: 155,
		// 	flex: 1,
		// 	headerName: "Annual Balance",
		// 	renderHeader: () => (
		// 		<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
		// 			{"Annual Balance"}
		// 		</span>
		// 	),
		// 	renderCell: (params) => (
		// 		<Typography
		// 			sx={{
		// 				textOverflow: "ellipsis",
		// 				width: "100%",
		// 				whiteSpace: "nowrap",
		// 				overflow: "hidden",
		// 				padding: "0px 4px",
		// 				fontStyle: "roboto",
		// 				fontWeight: "400",
		// 				fontSize: "14px",
		// 			}}
		// 		>
		// 			{params.row.annualBalance} hour
		// 		</Typography>
		// 	),
		// },

		{
			field: "locations",
			type: "string",
			minWidth: 45,
			flex: 1.5,
			headerName: "locations",
			renderHeader: () => (
				<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
					{"Applicable Locations"}
				</span>
			),
			renderCell: (params) => {
				return params.row.isEnabledAcrossCompany ? (
					<Typography
						sx={{
							textOverflow: "ellipsis",
							width: "100%",
							whiteSpace: "nowrap",
							overflow: "hidden",
							padding: "0px 4px",
							fontStyle: "roboto",
							fontWeight: "400",
							fontSize: "14px",
						}}
					>
						All Locations
					</Typography>
				) : (
					<LocationFilters locations={params.row.enabledLocations} />
				);
			},
			valueGetter: (params) => {
				const locs = params.row.isEnabledAcrossCompany
					? isArray(locations) && locations.length
					: params.row.enabledLocations.length;
				return locs;
			},
		},

		// {
		// 	field: "supervisorApproval",
		// 	type: "string",
		// 	minWidth: 155,
		// 	flex: 1,
		// 	headerName: "Supervisor approval",
		// 	renderHeader: () => (
		// 		<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
		// 			{"Supervisor approval"}
		// 		</span>
		// 	),
		// 	renderCell: (params) => (
		// 		<Typography
		// 			sx={{
		// 				textOverflow: "ellipsis",
		// 				width: "100%",
		// 				whiteSpace: "nowrap",
		// 				overflow: "hidden",
		// 				padding: "0px 4px",
		// 				fontStyle: "roboto",
		// 				fontWeight: "400",
		// 				fontSize: "14px",
		// 			}}
		// 		>
		// 			{params.row.supervisorApproval ? "Yes" : "No"}
		// 		</Typography>
		// 	),
		// },
		// {
		// 	field: "hrApproval",
		// 	type: "string",
		// 	minWidth: 125,
		// 	flex: 1,
		// 	headerName: "HR approval",
		// 	renderHeader: () => (
		// 		<span style={{ padding: "0px 4px", fontWeight: "bold" }}>
		// 			{"HR approval"}
		// 		</span>
		// 	),
		// 	renderCell: (params) => (
		// 		<Typography
		// 			sx={{
		// 				textOverflow: "ellipsis",
		// 				width: "100%",
		// 				whiteSpace: "nowrap",
		// 				overflow: "hidden",
		// 				padding: "0px 4px",
		// 				fontStyle: "roboto",
		// 				fontWeight: "400",
		// 				fontSize: "14px",
		// 			}}
		// 		>
		// 			{params.row.hrApproval ? "Yes" : "No"}
		// 		</Typography>
		// 	),
		// },
		{
			field: "actions",
			type: "actions",
			minWidth: 80,
			flex: 1,
			sortable: false,
			cellClassName: "hover-column-cell",
			renderHeader: () => <></>,
			headerClassName: "hover-column-header",
			renderCell: (params: any) => {
				const payload = {
					id: params.row.id,
					name: params.row.name,
					description: params.row.description,
					timeOffIncrementNumber: params.row.incrementHours,
					timeOffIncrementMetric: "",
					advancedNoticeNumber: params.row.applyBeforeHours,
					advanceNoticeMetric: params.row.advanceNoticeMetric,
					validFrom: "",
					isEnabledAcrossCompany: params.row.isEnabledAcrossCompany,
					locations: params.row.isEnabledAcrossCompany
						? locations.map((loc: { id: number }) => loc.id)
						: params.row.enabledLocations?.map(
								(loc: { id: number }) => loc.id
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  ) ?? [],
					locs: params.row.isEnabledAcrossCompany
						? locations
						: params.row.enabledLocations,
				};

				return (
					<Box
						className='hover-content'
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							minWidth: "100px",
							padding: "12px 16px 8px 16px",
							backgroundColor: "#FFF",
							borderRadius: "4px",
							marginLeft: "24px",
						}}
					>
						<Box
							sx={{
								color: "#6c6c6c",
								"&:hover": {
									color: "#000000",
									transform: "scale(1.2)",
								},
								cursor: "pointer",
							}}
						>
							<Tooltip title='Edit'>
								<EditIcon
									id={`edit-job-${params?.row?.id}-enabled`}
									fontSize='small'
									onClick={() => {
										handleOpenEdit(payload);
									}}
								/>
							</Tooltip>
						</Box>
						<Box
							sx={{
								color: "#6c6c6c",
								"&:hover": {
									color: "red",
									transform: "scale(1.2)",
								},
								cursor: "pointer",
							}}
						>
							<Tooltip title='Deactivate'>
								<DeleteIcon
									id={`delete-job-${params?.row?.id}-enabled`}
									sx={{ marginLeft: "8px" }}
									fontSize='small'
									onClick={() => {
										handleDelete(params.row.id, params.row.name);
									}}
								/>
							</Tooltip>
						</Box>
					</Box>
				);
			},
		},
	];

	return {
		columns,
	};
};

export default useGatAttendanceColumns;
