// TODO - Type 'any' needs to be fixed.
/* eslint-disable no-mixed-spaces-and-tabs */
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Box, CircularProgress, MenuItem, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

import ObsoleteTag from "../../../../../design/ui/ObsoleteTag";
import useSchedulerData from "../../../controllers/use-scheduler-data";
import SkillTag from "./skill-tag";

type Skill = {
	id: string;
	name: string;
	isActive: boolean;
};

type SelectSkillsProps = {
	values: any;
	setFieldValue: (key: string, value: Skill[]) => void;
	getNumberOfStaff?: (
		jobRole: string | null,
		locationId: string,
		startTime: string,
		endTime: string,
		shiftSkills?: any[]
	) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const SelectSkills: React.FC<SelectSkillsProps> = ({
	values,
	setFieldValue,
	getNumberOfStaff,
}) => {
	const {
		maxWorkersByJobLocation,
		filterAllSkills,
		allSkillLevels,
		defaultShiftsSkillLevel,
	} = useSchedulerData();
	const [error, setError] = React.useState(false);

	const skillLevels = allSkillLevels.reduce((acc: any, skillLevel: any) => {
		acc[skillLevel.level] = skillLevel;
		return acc;
	}, {});
	return (
		<Box>
			<Autocomplete
				multiple
				limitTags={2}
				id='multiple-limit-tags'
				options={filterAllSkills}
				isOptionEqualToValue={(option: Skill, value: Skill) =>
					option.id === value.id
				}
				getOptionLabel={(option: Skill) => option.name}
				value={values.shiftSkills.length > 0 ? values.shiftSkills : []}
				disabled={!values.locationId}
				getOptionDisabled={(option: Skill) => !option.isActive}
				readOnly={values.shiftPublished}
				onChange={(event: any, newValue: Skill[] | []) => {
					const skillWithIdAndLevel = [
						...newValue.map((skill) => ({
							...skill,
							skillId: skill.id,
							skillLevelId: skillLevels[defaultShiftsSkillLevel].id,
							skillName: skill.name,
							level: skillLevels[defaultShiftsSkillLevel].level,
						})),
					];
					if (newValue.length > 5) {
						setError(true);
						return;
					}
					setError(false);
					setFieldValue("shiftSkills", skillWithIdAndLevel);
					if (getNumberOfStaff) {
						getNumberOfStaff(
							null,
							values.locationId,
							values.startTime,
							values.endTime,
							skillWithIdAndLevel
						);
					}
				}}
				disableCloseOnSelect
				renderInput={(params) => (
					<TextField
						{...params}
						variant='filled'
						label='Skills'
						placeholder='Select Skills'
					/>
				)}
				renderOption={(props, option, { selected }) => (
					<li
						{...props}
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							opacity: 1,
						}}
					>
						<Box sx={{ opacity: !option.isActive ? "0.38" : "1" }}>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							{option.name}
						</Box>
						{!option.isActive && <ObsoleteTag />}
					</li>
				)}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						// eslint-disable-next-line react/jsx-key
						<Chip
							variant='outlined'
							label={option.name}
							avatar={
								<SkillTag defaultShiftsSkillLevel={defaultShiftsSkillLevel} />
							}
							size='medium'
							sx={{
								backgroundColor: "#f8f8f8",
							}}
							{...getTagProps({ index })}
						/>
					))
				}
				componentsProps={{
					popper: {
						placement: "bottom",
						modifiers: [
							{
								name: "flip",
								enabled: false,
							},
							{
								name: "preventOverflow",
								enabled: false,
							},
						],
					},
				}}
			/>
			<Box>
				{error ? (
					<Typography variant='caption' color='error'>
						Maximum 5 skiils can be selected
					</Typography>
				) : null}
			</Box>

			<Typography
				variant='caption'
				sx={{
					marginTop: "2px",
					color: maxWorkersByJobLocation.data === 0 ? "red" : "auto",
				}}
			>
				{maxWorkersByJobLocation.status === "pending" ? (
					<CircularProgress size={20} />
				) : maxWorkersByJobLocation.status === "fulfilled" &&
				  values.shiftSkills.length > 0 ? (
					`${maxWorkersByJobLocation.data}  worker(s) have ${
						values.shiftSkills.length === 1 ? "this skill." : "these skills."
					}`
				) : (
					""
				)}
			</Typography>
		</Box>
	);
};

export default SelectSkills;
