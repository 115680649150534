import LoadingButton from "@mui/lab/LoadingButton";
import {
	Alert,
	Box,
	Button,
	DialogTitle,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { changeUserStatus } from "../../../../store/gat-admin-slice";
import useSupervisorsData from "../controllers/use-supervisors-data";

export default function ConfirmDialog({
	open,
	onClose,
	action,
	text,
	title,
	item,
}: {
	open: boolean;
	onClose: () => void;
	action: () => void;
	text: string;
	title?: string;
	item?: any;
}) {
	const { updateUserStatus, companyUsers, changeUserStatusErrorMessage } =
		useSupervisorsData();
	const dispatch = useDispatch();
	const [selectedUser, setSelectedUser] = useState<number | null>(null);

	const { id, isActive, username, assignedWorkers } = item;

	const handleSubmit = () => {
		if (selectedUser !== null) {
			dispatch(
				changeUserStatus({
					userId: id,
					isActive: !isActive,
					alternateSupervisorId: selectedUser,
				})
			);
		}
	};
	const associatedUsers = companyUsers.filter(
		(user: { id: number; isActive: boolean }) => user.id !== id && user.isActive
	);

	return (
		<Dialog
			open={open}
			// TransitionComponent={Transition}
			keepMounted
			onClose={() => onClose()}
		>
			<DialogTitle>
				{isActive ? "Deactivate User" : "Activate User"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-slide-description'>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontSize: "16px",
							fontWeight: 400,
							lineHeight: "28px",
							color: "#000000DE",
						}}
					>
						{isActive
							? `Are you sure you want to deactivate ${username}?`
							: `Are you sure you want to activate ${username}?`}
					</Typography>

					{assignedWorkers > 0 && isActive ? (
						<Box mt={1}>
							<Alert severity='error'>
								{assignedWorkers} Worker(s) reporting to this Supervisor should
								be moved to a new Supervisor to continue.
							</Alert>
							<TextField
								label='Select a Supervisor'
								variant='filled'
								select
								fullWidth
								sx={{
									marginTop: "8px",
								}}
								value={selectedUser || ""}
								onChange={(e) => {
									const user = associatedUsers.find(
										(user: { id: number }) =>
											user.id === parseInt(e.target.value)
									);
									setSelectedUser(user ? parseInt(user.id) : null);
								}}
							>
								{associatedUsers.length > 0 ? (
									associatedUsers.map(
										(user: {
											id: number;
											firstName: string;
											lastName: string;
										}) => (
											<MenuItem key={user.id} value={user.id}>
												{`${user.firstName} ${user.lastName}`}
											</MenuItem>
										)
									)
								) : (
									<MenuItem disabled value=''>
										<em>No Associated Supervisor</em>
									</MenuItem>
								)}
							</TextField>
						</Box>
					) : null}
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ marginBottom: "16px", marginRight: "16px" }}>
				<Button onClick={() => action()} color='error' variant='outlined'>
					Cancel
				</Button>
				<LoadingButton
					onClick={handleSubmit}
					variant='contained'
					color={isActive ? "error" : "primary"}
					disabled={assignedWorkers > 0 && isActive && !selectedUser}
					loading={
						updateUserStatus === "pending" || updateUserStatus === "fulfilled"
					}
				>
					{isActive ? "Deactivate" : "Activate"}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
