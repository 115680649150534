import React from "react";
import { useSelector } from "react-redux";

import { StoreType } from "../../../utils/types";

const useWorkerRequestsData = () => {
	const allSwapRequests = useSelector(
		(state: StoreType) => state.workerRequests.allShiftSwapRequests
	);
	const allSwapRequestsStatus = useSelector(
		(state: StoreType) => state.workerRequests.shiftSwapRequests.status
	);

	const updateRequestsStatus = useSelector(
		(state: StoreType) => state.workerRequests.updateSwapRequests.status
	);
	const allRegularRequests = useSelector(
		(state: StoreType) => state.workerRequests.allRegularRequests
	);
	const getAllRegularRequestsStatus = useSelector(
		(state: StoreType) => state.workerRequests.getAllRegularRequests.status
	);
	const shiftFormData = useSelector(
		(state: StoreType) => state.workerRequests.shiftFormData
	);
	const allRequestStatuses = useSelector(
		(state: StoreType) => state.workerRequests.allRequestStatuses
	);
	const requestActionStatus = useSelector(
		(state: StoreType) => state.workerRequests.requestShiftAction.status
	);
	const requestActionErrorMessage = useSelector(
		(state: StoreType) => state.workerRequests.requestShiftAction.errorMessage
	);

	const allJobs = useSelector(
		(state: StoreType) => state.supervisor.masterData.allJobRoles
	);

	const workerRequestMetaData = useSelector(
		(state: StoreType) => state.workerRequests.workerRequestMetaData
	);

	const attendanceManagement = useSelector(
		(state: StoreType) => state.user.userData.attendanceManagement
	);

	const swapAndCancelShift = useSelector(
		(state: StoreType) => state.user.userData.swapAndCancelShift
	);

	const renotifyWorkersStatus = useSelector(
		(state: StoreType) => state.supervisor.renotifyWorkers.status
	);

	return {
		allSwapRequests,
		allSwapRequestsStatus,
		updateRequestsStatus,
		allRegularRequests,
		getAllRegularRequestsStatus,
		shiftFormData,
		allRequestStatuses,
		requestActionStatus,
		requestActionErrorMessage,
		allJobs,
		workerRequestMetaData,
		attendanceManagement,
		swapAndCancelShift,
		renotifyWorkersStatus,
	};
};

export default useWorkerRequestsData;
