import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import axios from "axios";

import {
  FLEX_WORKER,
  FULL_TIME_WORKER,
  PART_TIME_WORKER,
} from "../../../assets/constants";
import {
  getSelectedCompany,
  getWeeklyHoursLimitMessage,
  isFullTimeWorker,
  isPartTimeWorker,
  parseLocalDate,
} from "../../web-worker-app/util/helper";
import {
  ABSENT,
  EXCUSED_ABSENT,
  EXCUSED_TARDY,
  OVERTIME,
  PART_TIME,
  PRESENT,
  REGULAR,
  TARDY,
} from "../assets/constants";
import { Shift } from "../types";
import { overwriteShiftObjectsWithIds } from "../util/helper";
import { addStartDateEndDate } from "../util/helper";

// const workerId = "911A9AF8-D82F-4749-919C-33D571A5AE02";

export const getPasscode = createAsyncThunk(
  "worker/login",
  /**  @param  {workerPhoneNumber: string} */
  async ({ workerPhoneNumber }: { workerPhoneNumber: string }, thunkAPI) => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${process.env.REACT_APP_API_END_POINT}/Workers/GetPassCode`,
        {
          workerPhoneNumber,
        }
      );

      if (response.status === 200) {
        return thunkAPI.fulfillWithValue({ workerPhoneNumber });
      }
      return thunkAPI.rejectWithValue({ ...response.data, workerPhoneNumber });
    } catch (err: any) {
      // console.log('thunkREJECT: ', err?.response, ' sdklfnsd: ', err)
      return thunkAPI.rejectWithValue({
        ...err.response?.data,
        workerPhoneNumber,
      });
    }
  }
);

export const verifyPasscode = createAsyncThunk(
  "worker/verify",
  /**  @param  {workerPhoneNumber: string,passCode:string} */
  async (
    {
      workerPhoneNumber,
      passCode,
    }: { workerPhoneNumber: string; passCode: string },
    thunkAPI
  ) => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${process.env.REACT_APP_API_END_POINT}/Workers/VerifyPassCode`,
        {
          workerPhoneNumber,
          passCode,
        }
      );

      if (response.status === 200) {
        return thunkAPI.fulfillWithValue({
          workerPhoneNumber,
          passCode,
          ...response.data,
        });
      }
      return thunkAPI.rejectWithValue(response.data);
    } catch (err: any) {
      // console.log('thunkREJECT: ', err?.response, ' sdklfnsd: ', err)
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

// autoLogin async

export const Refresh = createAsyncThunk(
  "worker/refresh",
  async (payload, thunkAPI) => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${process.env.REACT_APP_API_END_POINT}/Workers/Refresh`,
        {
          refreshToken: localStorage.getItem("worker-refreshToken"),
          token: localStorage.getItem("worker-token"),
        }
      );

      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response?.data);
      }
      return thunkAPI.rejectWithValue(response.data);
    } catch (err: any) {
      // console.log('thunkREJECT: ', err?.response, ' sdklfnsd: ', err)
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  }
);

export const getConfirmedShifts = createAsyncThunk(
  "worker/getConfirmedShifts",
  /**
   * @param payload {startDate: string, endDate: string, updating: boolean}
   * @param thunkAPI
   */
  async (
    payload: {
      startDate: string;
      endDate: string;
      updating: boolean;
    },
    thunkAPI
  ) => {
    // const workerid = thunkAPI.getState().worker.workerId || payload;
    const data = {};
    try {
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_END_POINT}/Workers/Shifts?startDate=${payload.startDate}&endDate=${payload.endDate}`,
        {
          // params: {
          //   workerid,
          // },
          data,
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );

      // console.log("confirmed",response);

      if (response.status === 200) {
        const allConfirmedShifts = response.data;
        const allConfirmedShiftsWithDates =
          addStartDateEndDate(allConfirmedShifts);
        return thunkAPI.fulfillWithValue({
          shifts: allConfirmedShiftsWithDates,
          updating: payload.updating,
        });
      } else if (response.status === 204) {
        return thunkAPI.fulfillWithValue([]);
      } else return thunkAPI.rejectWithValue("An error occurred");
    } catch (error: any) {
      if (error.message === "Request failed with status code 404")
        return thunkAPI.rejectWithValue({ error: "No data found" });
      else return thunkAPI.rejectWithValue({ error: error.response.data });
    }
  }
);

export const getSkillLevels = createAsyncThunk(
  "worker/getSkillLevels",
  async (payload, thunkAPI) => {
    try {
      const data = {};
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_END_POINT}/Skill/Levels`,
        {
          data,
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      if (error.message === "Request failed with status code 404")
        return thunkAPI.rejectWithValue({ error: "No data found" });
      else return thunkAPI.rejectWithValue({ error: error.response.data });
    }
  }
);

export const cancelShift = createAsyncThunk(
  "worker/cancelShift",
  /**  @param payload {{shiftId: number, status: string, startDateTime: string, endDateTime: string}}
   * @param thunkAPI
   */
  async (
    payload: {
      shiftId: number;
      status: string;
      startDateTime: string;
      endDateTime: string;
    },
    thunkAPI
  ) => {
    try {
      const data = {
        shiftId: payload.shiftId,
        status: payload.status,
        forceCancel: false,
        source: "mobile",
      };
      console.log("data", data);
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${process.env.REACT_APP_API_END_POINT}/Workers/Signup`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );

      if (!response) throw new Error("response not defined");
      else if (response.status === 200)
        if (Object.prototype.hasOwnProperty.call(response.data, "details")) {
          return thunkAPI.fulfillWithValue({
            ...response.data,
            startDateTime: payload.startDateTime,
            endDateTime: payload.endDateTime,
          });
        } else {
          return thunkAPI.fulfillWithValue(payload);
        }
      else
        return thunkAPI.rejectWithValue(
          "Error canceling this shift. Please contact support"
        );
    } catch (error: any) {
      if (Object.prototype.hasOwnProperty.call(error, "response")) {
        const errorResponse = error.response.data;
        if (Object.prototype.hasOwnProperty.call(errorResponse, "message")) {
          let finalErrorResponse = errorResponse.message;
          // handle contact from error response
          if (Object.prototype.hasOwnProperty.call(errorResponse, "contact")) {
            if (errorResponse.contact) {
              const contactNumbers = errorResponse.contact.split(",");
              contactNumbers.forEach((contactNumber: string, index: number) => {
                if (index === 0) {
                  // Append contact to error response
                  finalErrorResponse = `${finalErrorResponse.trim()} +${contactNumber}`;
                } else {
                  finalErrorResponse = `${finalErrorResponse.trim()} or +${contactNumber}`;
                }
              });
            }
          }
          return thunkAPI.rejectWithValue(finalErrorResponse);
        } else {
          return thunkAPI.rejectWithValue("An error occurred");
        }
      } else {
        return thunkAPI.rejectWithValue(
          "Error canceling this shift. Please contact support"
        );
      }
    }
  }
);

export const forceCancelShift = createAsyncThunk(
  "worker/forceCancelShift",
  async (
    payload: { shiftId: number; startDateTime: string; endDateTime: string },
    thunkAPI
  ) => {
    try {
      const data = {
        shiftId: payload.shiftId,
        status: "C",
        forceCancel: true,
      };
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${process.env.REACT_APP_API_END_POINT}/Workers/Signup`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );
      if (response?.status === 200) {
        return thunkAPI.fulfillWithValue(payload);
      } else {
        return thunkAPI.rejectWithValue(
          "Error canceling this shift. Please contact support"
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(
        "Error canceling this shift. Please contact support"
      );
    }
  }
);

export const getUpcomingShifts = createAsyncThunk(
  "worker/getUpcomingShifts",
  /**
   * @param payload {startDate: string, endDate: string, updating: boolean}
   * @param thunkAPI
   */
  async (
    payload: {
      startDate: string;
      endDate: string;
      updating: boolean;
    },
    thunkAPI
  ) => {
    // const workerid = thunkAPI.getState().worker.workerId || payload;
    const data = {};
    try {
      const { startDate, endDate, updating } = payload as unknown as {
        startDate: string;
        endDate: string;
        updating: string;
      };
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_END_POINT}/Workers/UpcomingShifts?startDate=${startDate}&endDate=${endDate}`,
        {
          data,
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );

      //console.log("uc-res",response);

      if (response.status === 200) {
        const allUpcomingShifts = response.data;

        const allUpcomingShiftsWithDates =
          addStartDateEndDate(allUpcomingShifts);
        return thunkAPI.fulfillWithValue({
          shifts: allUpcomingShiftsWithDates,
          updating: updating, // If updating then replace existing shifts
        });
      } else if (response.status === 204) {
        return thunkAPI.fulfillWithValue([]);
      } else return thunkAPI.rejectWithValue("An error occurred");
    } catch (error: any) {
      // console.log(error);
      if (error.message === "Request failed with status code 404")
        return thunkAPI.rejectWithValue({ error: "No data found" });
      else
        return thunkAPI.rejectWithValue({
          error: error.response.data?.message,
        });
    }
  }
);

// A: Accept
export const confirmShift = createAsyncThunk(
  "worker/confirmShift",
  /**  @param payload {{shiftId: number, status: string, checkConsecutiveShiftSignup: boolean, startDateTime: string, endDateTime: string}}
   * @param thunkAPI
   */
  async (
    payload: {
      shiftId: number;
      status: string;
      checkConsecutiveShiftSignup: boolean;
      startDateTime: string;
      endDateTime: string;
    },
    thunkAPI
  ) => {
    try {
      const {
        shiftId,
        status,
        checkConsecutiveShiftSignup,
        startDateTime,
        endDateTime,
      } = payload as unknown as {
        shiftId: number;
        status: string;
        checkConsecutiveShiftSignup: boolean;
        startDateTime: string;
        endDateTime: string;
      };
      const data = {
        // workerId: thunkAPI.getState().worker.workerId,
        shiftId: shiftId,
        status: status,
        checkConsecutiveShiftSignup: checkConsecutiveShiftSignup,
      };
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${process.env.REACT_APP_API_END_POINT}/Workers/Signup`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );

      // console.log(response);

      if (response.status === 200) return thunkAPI.fulfillWithValue(payload);
    } catch (error: any) {
      const startDateTime = Object.prototype.hasOwnProperty.call(
        payload,
        "startDateTime"
      )
        ? payload.startDateTime
        : "";
      const endDateTime = Object.prototype.hasOwnProperty.call(
        payload,
        "endDateTime"
      )
        ? payload.endDateTime
        : "";
      if (Object.prototype.hasOwnProperty.call(error, "response")) {
        let errorResponse = error.response;
        if (Object.prototype.hasOwnProperty.call(errorResponse, "data")) {
          errorResponse = error.response.data;
          errorResponse = {
            ...errorResponse,
            startDateTime,
            endDateTime,
          };
          if (Object.prototype.hasOwnProperty.call(errorResponse, "message")) {
            return thunkAPI.rejectWithValue({
              ...errorResponse,
              startDateTime,
              endDateTime,
            });
          } else {
            return thunkAPI.rejectWithValue({
              startDateTime,
              endDateTime,
            });
          }
        } else {
          return thunkAPI.rejectWithValue({
            message: "Error confirming shift.",
            startDateTime,
            endDateTime,
          });
        }
      } else if (Object.prototype.hasOwnProperty.call(error, "message")) {
        return thunkAPI.rejectWithValue({
          ...error,
          startDateTime,
          endDateTime,
        });
      } else {
        return thunkAPI.rejectWithValue({
          message: "Error confirming shift.",
          startDateTime,
          endDateTime,
        });
      }
    }
  }
);

export const viewShift = createAsyncThunk(
  "worker/viewShift",
  async (payload: any, thunkAPI: any) => {
    try {
      const viewedShifts = thunkAPI.getState().worker.viewShift.viewedShifts;
      if (!viewedShifts.includes(payload)) {
        const data = {
          shiftId: payload,
        };
        const uninterceptedAxiosInstance = axios.create();
        const response = await uninterceptedAxiosInstance.post(
          `${process.env.REACT_APP_API_END_POINT}/Workers/ViewShift`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("worker-token"),
            },
          }
        );
        if (response.status === 200)
          return thunkAPI.fulfillWithValue([...viewedShifts, payload]);
      } else {
        return thunkAPI.fulfillWithValue(viewedShifts);
      }
    } catch (error: any) {
      if (Object.prototype.hasOwnProperty.call(error, "response")) {
        const errorResponse = error.response.data;
        if (Object.prototype.hasOwnProperty.call(errorResponse, "message")) {
          return thunkAPI.rejectWithValue(errorResponse.message);
        } else {
          return thunkAPI.rejectWithValue("An error occurred");
        }
      } else if (error?.message === "Request failed with status code 404")
        return thunkAPI.rejectWithValue("No data found");
      else {
        if (Object.prototype.hasOwnProperty.call(error, "message")) {
          return thunkAPI.rejectWithValue(error.message);
        } else {
          return thunkAPI.rejectWithValue("An error occurred");
        }
      }
    }
  }
);

export const declineShift = createAsyncThunk(
  "worker/declineShift",
  /**
   * @param payload {id: number}
   * @param thunkAPI
   */
  async (payload: number, thunkAPI) => {
    try {
      const data = {
        // workerId: thunkAPI.getState().worker.workerId,
        shiftId: payload,
        status: "D",
      };
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.post(
        `${process.env.REACT_APP_API_END_POINT}/Workers/Signup`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );

      // console.log(response);

      if (response.status === 200) return thunkAPI.fulfillWithValue(payload);
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getProfile = createAsyncThunk(
  "worker/getProfile",
  async (payload, thunkAPI) => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_END_POINT}/Workers/Profile`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error: any) {
      console.error(error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getRegularShifts = createAsyncThunk(
  "worker/getRegularShifts",
  /**
   * @param payload {startDate: string, endDate: string, updating: boolean}
   * @param thunkAPI
   */
  async (
    payload: {
      startDate: string;
      endDate: string;
      updating: boolean;
    },
    thunkAPI
  ) => {
    try {
      const { startDate, endDate, updating } = payload as unknown as {
        startDate: string;
        endDate: string;
        updating: boolean;
      };
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_END_POINT}/Workers/rShifts?startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );

      if (response?.status === 200) {
        const allRegularShifts = response?.data;
        const allRegularShiftsWithDates = addStartDateEndDate(allRegularShifts);
        return thunkAPI.fulfillWithValue({
          shifts: allRegularShiftsWithDates,
          updating: updating,
        });
      } else if (response?.status === 204) {
        return thunkAPI.fulfillWithValue([]);
      } else return thunkAPI.rejectWithValue("An error occurred");
    } catch (error: any) {
      if (Object.prototype.hasOwnProperty.call(error, "response")) {
        const errorResponse = error.response.data;
        if (Object.prototype.hasOwnProperty.call(errorResponse, "message")) {
          return thunkAPI.rejectWithValue(errorResponse.message);
        } else {
          return thunkAPI.rejectWithValue("An error occurred");
        }
      } else if (error?.message === "Request failed with status code 404")
        return thunkAPI.rejectWithValue("No data found");
      else {
        if (Object.prototype.hasOwnProperty.call(error, "message")) {
          return thunkAPI.rejectWithValue(error.message);
        } else {
          return thunkAPI.rejectWithValue("An error occurred");
        }
      }
    }
  }
);

export const getAcknowledgements = createAsyncThunk(
  "worker/acknowledgements",
  async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_END_POINT}/Workers/Acknowledgements`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );
      if (response.status === 200) {
        return fulfillWithValue(response.data);
      } else
        return rejectWithValue(
          "Unexpected response from server. Please contact support."
        );
    } catch (error: any) {
      return rejectWithValue(
        "Unexpected response from server. Please contact support."
      );
    }
  }
);

export const getCustomLogo = createAsyncThunk(
  "worker/getCustomLogo",
  async (payload, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const companyId = (getState() as any).worker.settings.companyId as string;
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_END_POINT}/Companies/Branding?CompanyId=${companyId}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: localStorage.getItem("worker-token"),
          },
        }
      );
      if (response.status === 200) {
        return fulfillWithValue(response.data);
      } else return rejectWithValue(response.data);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSettings = createAsyncThunk(
  "worker/GetSettings",
  async (payload, thunkAPI) => {
    try {
      const workerToken = localStorage.getItem("worker-token");
      const uninterceptedAxiosInstance = axios.create();
      const response = await uninterceptedAxiosInstance.get(
        `${process.env.REACT_APP_API_END_POINT}/Workers/GetSettings`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: workerToken,
          },
        }
      );
      if (response.status === 200) {
        if (Array.isArray(response.data) && response.data.length > 1) {
          const responseArray = response.data;
          const data = {
            smsNotifications: false,
            smsNotificationsId: 2,
            smsDailyShiftReminders: false,
            smsDailyShiftRemindersId: 1,
            displayAvailability: false,
            displayAvailabilityId: 3,
            showScore: false,
            showScoreId: 4,
            showSkill: false,
            showSkillId: 7,
            initialized: true,
            autoAccept: false,
            autoAcceptId: 5,
            dailyMaxHours: 0,
            dailyMaxHoursId: 6,
            workerAttendanceAcknowledgement: false,
            workerAttendanceAcknowledgementId: 0,
            showShiftAutoAccept: false,
            showShiftAutoAcceptId: 99,
            workerProfileInfo: undefined,
            workerProfileInfoId: 0,
            companyId: undefined,
            workerTypeName: FLEX_WORKER,
            workerTypeText: "Flex",
            maxWorkHours: 0,
            remainingWorkHours: 0,
            showWeeklyHoursLimitNoticeOn: 0.2,
            fullTimeShiftSwapCancelEnabled: false,
            timeOffEnabled: false,
            customizedBrandingEnabled: false,
          };
          responseArray.forEach((setting) => {
            switch (setting["code"]) {
              case "DailyReminderSMS":
                // Daily shift reminders
                data["smsDailyShiftReminders"] = setting["value"] === true;
                data["smsDailyShiftRemindersId"] = setting["id"];
                break;
              case "SMSNotifications":
                // SMS Notifications
                data["smsNotifications"] = setting["value"] === true;
                data["smsNotificationsId"] = setting["id"];
                break;
              case "ShowAvailability":
                // Availability
                data["displayAvailability"] = setting["value"] === true;
                data["displayAvailabilityId"] = setting["id"];
                break;
              case "ShowScore":
                // Score in profile
                data["showScore"] = setting["value"] === true;
                data["showScoreId"] = setting["id"];
                break;
              case "ShowSkill":
                data["showSkill"] = setting["value"] === true;
                data["showSkillId"] = setting["id"];
                break;
              case "ShiftAutoAccept":
                // Auto-accept shift
                data["autoAccept"] = setting["value"] === true;
                data["autoAcceptId"] = setting["id"];
                break;
              case "DailyMaxWorkHours":
                // Max hours to work per day
                data["dailyMaxHours"] = setting["value"];
                data["dailyMaxHoursId"] = setting["id"];
                break;
              case "WorkerAttendAck":
                // Show hide worker attendance acknowledgement
                data["workerAttendanceAcknowledgement"] = setting["value"];
                data["workerAttendanceAcknowledgementId"] = setting["id"];
                break;
              case "ShowShiftAutoAccept":
                // Show hide auto accept option
                data["showShiftAutoAccept"] = setting["value"];
                data["showShiftAutoAcceptId"] = setting["id"];
                break;
              case "WorkerProfileInfo":
                // Worker profile info
                data["workerProfileInfo"] = setting["value"];
                data["workerProfileInfoId"] = setting["id"];
                break;
              case "CompanyId":
                data["companyId"] = setting["value"];
                break;
              case "ShowWeeklyHoursLimitNoticeOn":
                // Show weekly hours limit notice on
                data["showWeeklyHoursLimitNoticeOn"] = setting["value"];
                break;
              case "ftShift.swapAndCancelEnabled":
                // Full time worker shift swap and call out enabled or not
                data["fullTimeShiftSwapCancelEnabled"] =
                  setting["value"] ?? false;
                break;
              case "attendMgmt.enabled":
                // Attendance management is enabled or not
                data["timeOffEnabled"] = setting["value"];
                break;
              case "customizedBrandingEnabled":
                // Custom logo for a company is enabled or not
                data["customizedBrandingEnabled"] = setting["value"];
                break;
              default:
                break;
            }
          });
          return thunkAPI.fulfillWithValue(data);
        } else {
          return thunkAPI.rejectWithValue(
            "Unexpected response from server. Please contact support."
          );
        }
      }
    } catch (error: any) {
      if (Object.prototype.hasOwnProperty.call(error, "response")) {
        if (Object.prototype.hasOwnProperty.call(error, "data")) {
          const errorResponse = error.response.data;
          if (Object.prototype.hasOwnProperty.call(errorResponse, "message")) {
            return thunkAPI.rejectWithValue(errorResponse.message);
          } else {
            return thunkAPI.rejectWithValue("An error occurred");
          }
        } else {
          return thunkAPI.rejectWithValue("Error getting sms settings.");
        }
      } else if (Object.prototype.hasOwnProperty.call(error, "message")) {
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue("Error getting sms settings.");
      }
    }
  }
);

// import moduleName from 'a'
export const WebWorkerSlice = createSlice({
  name: "worker",
  initialState: {
    settings: {
      smsNotifications: false,
      smsNotificationsId: 2,
      smsDailyShiftReminders: false,
      smsDailyShiftRemindersId: 1,
      // Show availability to worker
      displayAvailability: false,
      displayAvailabilityId: 3,
      // Show score to worker
      showScore: false,
      showScoreId: 4,
      // Show skill to worker
      showSkill: false,
      showSkillId: 7,
      // Setting fetched once
      initialized: false,
      autoAccept: false,
      autoAcceptId: 5,
      dailyMaxHours: 0,
      dailyMaxHoursId: 6,
      // Setting from device
      pushNotification: false,
      workerAttendanceAcknowledgement: false,
      showShiftAutoAccept: false,
      workerProfileInfo: undefined,
      remainingWorkHours: 0,
      maxWorkHours: 0,
      workerTypeName: FLEX_WORKER,
      workerTypeText: "Flex",
      companyId: undefined,
      showWeeklyHoursLimitNoticeOn: 0.2,
      fullTimeShiftSwapCancelEnabled: false,
      timeOffEnabled: false,
      customizedBrandingEnabled: false,
      showShiftAutoAcceptId: 99,
      workerProfileInfoId: 0,
    },
    getSettings: {
      status: "idle",
      errorMessage: "",
    },
    workerId: "",
    workerType: FLEX_WORKER,
    location: 0,
    skillLevels: [],
    workerData: {
      firstName: "",
      lastName: "",
    },
    workerProfile: {
      data: {},
      status: "idle",
      errorMessage: "",
    },
    shiftsConfirmed: [] as Shift[],
    authenticated: false,
    login: {
      status: "idle",
      errorMessage: "",
    },
    getPasscode: {
      status: "idle",
      errorMessage: "",
    },
    verifyPasscode: {
      status: "idle",
      errorMessage: "",
      isLocked: false,
    },
    Refresh: {
      status: "idle",
      errorMessage: "",
    },
    upcomingShifts: [] as Shift[],
    getConfirmedShifts: {
      status: "idle",
      errorMessage: "",
      canLoadMore: true,
    },
    cancelShift: {
      status: "idle",
      errorMessage: "",
      type: "C",
      response: undefined,
      startDateTime: undefined,
      endDateTime: undefined,
    } as {
      status: string;
      errorMessage: string;
      type: string;
      response:
        | {
            details: string;
            message: string;
          }
        | undefined;
      startDateTime: string | undefined;
      endDateTime: string | undefined;
    },
    forceCancelShift: {
      status: "idle",
      errorMessage: "",
    },
    getUpcomingShifts: {
      status: "idle",
      errorMessage: "",
      canLoadMore: true,
    },
    confirmShift: {
      status: "idle",
      errorMessage: "",
      weeklyHoursLimitMessage: "",
      shifts: [],
    },
    viewShift: {
      status: "idle",
      errorMessage: "",
      viewedShifts: [] as any[],
    },
    declineShift: {
      status: "idle",
      errorMessage: "",
    },
    signupShiftDialog: {
      isOpen: false,
      shiftId: null,
      swap: false,
      swapId: null,
    },
    viewShiftDialog: {
      isOpen: false,
      shiftId: null,
      swap: false,
      status: "",
    },
    viewRegularShiftDialog: {
      isOpen: false,
      shift: undefined,
    },
    getSkillLevels: {
      status: "idle",
      errorMessage: "",
    },
    getRegularShifts: {
      status: "idle",
      errorMessage: "",
      canLoadMore: true,
    },
    regularShifts: [] as Shift[],
    customLogo: "",
    getCustomLogo: {
      status: "idle",
      errorMessage: "",
      response: [], // Do not use this directly for logo
    },
    getAcknowledgements: {
      status: "idle",
      errorMessage: "",
    },
    acknowledgements: [] as { attendStatus: string }[],
  },
  reducers: {
    setWorker: (state) => {
      state.authenticated = true;
    },
    setCustomLogo: (state, action) => {
      state.customLogo = action.payload;
    },
    setWorkerId: (state, action) => {
      state.workerId = action.payload;
    },
    setTabToViewShifts: (state) => {
      state.location = 1;
    },
    setTabToShiftSignup: (state) => {
      state.location = 0;
    },
    setTab: (state, action) => {
      const data = action.payload;
      state.location = data;
    },
    resetCancelShift: (state) => {
      state.cancelShift.status = "idle";
      state.cancelShift.errorMessage = "";
      state.cancelShift.type = "C";
      state.cancelShift.response = undefined;
      state.cancelShift.startDateTime = undefined;
      state.cancelShift.endDateTime = undefined;
    },
    resetConfirmShift: (state) => {
      state.confirmShift.status = "idle";
      state.confirmShift.errorMessage = "";
    },
    resetGetRegularShifts: (state) => {
      state.getRegularShifts.status = "idle";
      state.getRegularShifts.errorMessage = "";
      state.getRegularShifts.canLoadMore = true;
    },
    resetGetSettings: (state) => {
      state.getSettings.status = "idle";
      state.getSettings.errorMessage = "";
    },
    openSignupShiftDialog: (state, action) => {
      const { id, swap, swapId } = action.payload;
      state.signupShiftDialog.isOpen = true;
      state.confirmShift.status = "idle";
      state.confirmShift.errorMessage = "";
      state.signupShiftDialog.shiftId = id;
      state.signupShiftDialog.swap = swap;
      state.signupShiftDialog.swapId = swapId;
    },
    closeSignupShiftDialog: (state) => {
      state.signupShiftDialog.isOpen = false;
      state.signupShiftDialog.shiftId = null;
    },
    openViewShiftDialog: (state, action) => {
      state.viewShiftDialog.isOpen = true;
      state.viewShiftDialog.shiftId = action.payload.id;
      state.viewShiftDialog.swap = action.payload.swap;
      state.viewShiftDialog.status = action.payload.status;
    },
    openViewRegularShiftDialog: (state, action) => {
      state.viewRegularShiftDialog.isOpen = true;
      state.viewRegularShiftDialog.shift = action.payload;
    },
    closeViewShiftDialog: (state) => {
      state.viewShiftDialog.isOpen = false;
      state.viewShiftDialog.shiftId = null;
      state.viewShiftDialog.swap = true;
      state.viewShiftDialog.status = "";
      state.cancelShift.status = "idle";
      state.cancelShift.errorMessage = "";
      state.cancelShift.type = "C";
      state.cancelShift.response = undefined;
      state.cancelShift.startDateTime = undefined;
      state.cancelShift.endDateTime = undefined;
    },
    closeViewRegularShiftDialog: (state) => {
      state.viewRegularShiftDialog.isOpen = false;
      state.viewRegularShiftDialog.shift = undefined;
    },
    logout: (state) => {
      state.authenticated = false;
      state.getPasscode.status = "idle";
      state.getPasscode.errorMessage = "";
      state.verifyPasscode.status = "idle";
      state.verifyPasscode.errorMessage = "";
      state.verifyPasscode.isLocked = false;
      state.getCustomLogo.status = "idle";
      state.getCustomLogo.errorMessage = "";
      state.getCustomLogo.response = [];
      state.customLogo = "";
      state.shiftsConfirmed = [];
      state.upcomingShifts = [];
      state.getConfirmedShifts.status = "idle";
      state.getConfirmedShifts.errorMessage = "";
      state.getConfirmedShifts.canLoadMore = true;
      state.getRegularShifts.status = "idle";
      state.getRegularShifts.errorMessage = "";
      state.getRegularShifts.canLoadMore = true;
      state.getUpcomingShifts.status = "idle";
      state.getUpcomingShifts.errorMessage = "";
      state.getUpcomingShifts.canLoadMore = true;
      localStorage.removeItem("worker-token");
      localStorage.removeItem("worker-refreshToken");
      localStorage.removeItem("worker-details");
      state.Refresh.status = "idle";
      state.Refresh.errorMessage = "";
    },
    resetAcknowledgements: (state) => {
      return {
        ...state,
        getAcknowledgements: {
          status: "idle",
          errorMessage: "",
        },
        acknowledgements: [],
      };
    },
  },
  extraReducers: (builder) => {
    // getPasscode
    builder.addCase(getPasscode.fulfilled, (state, action) => {
      // const { workerPhoneNumber } = action.payload
      state.authenticated = false;
      state.getPasscode.errorMessage = "";
      state.getPasscode.status = "fulfilled";
    });
    builder.addCase(getPasscode.pending, (state, action) => {
      state.getPasscode.status = "pending";
      state.getPasscode.errorMessage = "";
      state.authenticated = false;
    });
    builder.addCase(getPasscode.rejected, (state, action: any) => {
      state.getPasscode.status = "error";
      const errorMessage = "An error occurred";
      state.getPasscode.errorMessage = action.payload
        ? action.payload.message
          ? action.payload.message
          : errorMessage
        : errorMessage;
      Sentry.captureMessage(
        `Login attempt failed - ${action.payload.workerPhoneNumber}`
      );
    });
    // Refresh
    builder.addCase(Refresh.fulfilled, (state, action) => {
      state.authenticated = true;
      state.Refresh.status = "fulfilled";
      if (Object.keys(action.payload).length > 0) {
        localStorage.setItem("worker-token", action.payload?.token);
        localStorage.setItem(
          "worker-refreshToken",
          action.payload?.refreshToken
        );
      }
    });
    builder.addCase(Refresh.pending, (state, action) => {
      state.Refresh.status = "pending";
    });
    builder.addCase(Refresh.rejected, (state, action: any) => {
      // Logout if refresh failed
      state.authenticated = false;
      state.getPasscode.status = "idle";
      state.getPasscode.errorMessage = "";
      state.verifyPasscode.status = "idle";
      state.verifyPasscode.errorMessage = "";
      state.verifyPasscode.isLocked = false;
      state.getCustomLogo.status = "idle";
      state.getCustomLogo.errorMessage = "";
      state.getCustomLogo.response = [];
      state.getSettings.status = "idle";
      state.getSettings.errorMessage = "";
      state.customLogo = "";
      state.shiftsConfirmed = [];
      state.upcomingShifts = [];
      state.getConfirmedShifts.status = "idle";
      state.getConfirmedShifts.errorMessage = "";
      state.getConfirmedShifts.canLoadMore = true;
      state.getRegularShifts.status = "idle";
      state.getRegularShifts.errorMessage = "";
      state.getRegularShifts.canLoadMore = true;
      state.getUpcomingShifts.status = "idle";
      state.getUpcomingShifts.errorMessage = "";
      state.getUpcomingShifts.canLoadMore = true;
      localStorage.removeItem("worker-token");
      localStorage.removeItem("worker-refreshToken");
      localStorage.removeItem("worker-details");
      state.Refresh.status = "idle";
      state.Refresh.errorMessage = "";
    });
    builder.addCase(verifyPasscode.fulfilled, (state, action) => {
      const { firstName, lastName, id, token, refreshToken } = action.payload;
      localStorage.setItem("worker-token", token);
      localStorage.setItem("worker-refreshToken", refreshToken);
      localStorage.setItem(
        "worker-details",
        JSON.stringify({ firstName, lastName })
      );
      state.authenticated = true;
      state.workerData.firstName = firstName;
      state.workerData.lastName = lastName;
      state.workerId = id;
      state.verifyPasscode.status = "fulfilled passcode";
      state.verifyPasscode.errorMessage = "";
      state.verifyPasscode.isLocked = false;
      state.getPasscode.status = "fulfilled idle";
    });
    builder.addCase(verifyPasscode.pending, (state, action) => {
      state.verifyPasscode.status = "pending";
      state.authenticated = false;
      state.verifyPasscode.errorMessage = "";
      state.getPasscode.status = "fulfilled idle";
    });
    builder.addCase(verifyPasscode.rejected, (state, action: any) => {
      state.verifyPasscode.status = "error passcode";
      const errorMessage =
        "The passcode you entered is not correct. Please enter the correct passcode.";
      state.verifyPasscode.errorMessage = action.payload
        ? action.payload.message
          ? action.payload.message
          : errorMessage
        : errorMessage;
      state.verifyPasscode.isLocked = action.payload
        ? action.payload.isLocked
          ? action.payload.isLocked
          : false
        : false;
      state.getPasscode.status = "fulfilled";
    });
    builder.addCase(getConfirmedShifts.pending, (state, action) => {
      state.getConfirmedShifts.status = "pending";
      state.getConfirmedShifts.canLoadMore = true;
    });
    builder.addCase(getConfirmedShifts.fulfilled, (state, action) => {
      const shifts = (
        action.payload as never as { shifts: Shift[]; updating: boolean }
      ).shifts;
      const updating = (
        action.payload as never as { shifts: Shift[]; updating: boolean }
      ).updating;
      let existingConfirmedShifts: Shift[] = [...state.shiftsConfirmed];
      let confirmedShifts: Shift[] = [];
      if (Array.isArray(shifts)) {
        // For full time worker normal confirmed shifts are overtime shifts
        // For a flex worker it is a regular shift
        shifts.forEach((obj) => {
          confirmedShifts.push({
            ...obj,
            isWorkerSkillExpired: Object.prototype.hasOwnProperty.call(
              obj,
              "shiftSkills"
            )
              ? obj.shiftSkills
                ? obj.shiftSkills.some((skill) => skill.isWorkerSkillExpired)
                : false
              : false,
            shiftType: isFullTimeWorker({ ...state.settings })
              ? OVERTIME
              : isPartTimeWorker({ ...state.settings })
              ? PART_TIME
              : "",
            status: obj.status ?? "",
          });
        });
      }
      state.getConfirmedShifts.status = "fulfilled";
      if (confirmedShifts.length === 0) {
        // If there is no confirmed shifts then set can load more to false
        state.getConfirmedShifts.canLoadMore = false;
      } else {
        if (!updating) {
          state.getConfirmedShifts.canLoadMore = true;
        }
        // Sort new confirmed shifts
        confirmedShifts = confirmedShifts.sort((a: Shift, b: Shift) => {
          return (
            new Date(a.startDateTime).getTime() -
            new Date(b.startDateTime).getTime()
          );
        });
        // Append new confirmed shift
        existingConfirmedShifts =
          existingConfirmedShifts.concat(confirmedShifts);
        // Remove duplicate shifts
        existingConfirmedShifts = existingConfirmedShifts.filter(
          (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
        );
        // Sort based on start date
        existingConfirmedShifts = existingConfirmedShifts.sort(
          (a: Shift, b: Shift) => {
            return (
              new Date(a.startDateTime).getTime() -
              new Date(b.startDateTime).getTime()
            );
          }
        );
        // Update shifts confirmed state
        state.shiftsConfirmed = updating
          ? confirmedShifts
          : existingConfirmedShifts;
      }
    });
    builder.addCase(getConfirmedShifts.rejected, (state, action: any) => {
      const error = Object.prototype.hasOwnProperty.call(
        action.payload?.error,
        "message"
      )
        ? action.payload?.error.message
        : action.payload?.error;
      if (error === "No data found") {
        state.getConfirmedShifts.status = "error";
        state.getConfirmedShifts.errorMessage =
          "You have no upcoming shifts scheduled at this time.";
      } else {
        state.getConfirmedShifts.status = "error";
        state.getConfirmedShifts.errorMessage =
          error ??
          "An error occurred. Please click the link in the sms / text message again.";
      }
    });
    builder.addCase(getUpcomingShifts.pending, (state, action) => {
      state.getUpcomingShifts.status = "pending";
      state.getUpcomingShifts.canLoadMore = true;
    });

    builder.addCase(getUpcomingShifts.fulfilled, (state, action) => {
      const shifts = (
        action.payload as unknown as { shifts: Shift[]; updating: boolean }
      ).shifts;
      const updating = (
        action.payload as unknown as { shifts: Shift[]; updating: boolean }
      ).updating;
      let existingUpcomingShifts: Shift[] = [...state.upcomingShifts];
      let upcomingShifts: Shift[] = [];
      if (Array.isArray(shifts)) {
        // For full time worker normal confirmed shifts are overtime shifts
        // For a flex worker it is a regular shift
        shifts.forEach((obj) => {
          upcomingShifts.push({
            ...obj,
            isWorkerSkillExpired: Object.prototype.hasOwnProperty.call(
              obj,
              "shiftSkills"
            )
              ? obj.shiftSkills
                ? obj.shiftSkills.some((skill) => skill.isWorkerSkillExpired)
                : false
              : false,
            shiftType: isFullTimeWorker({ ...state.settings })
              ? OVERTIME
              : isPartTimeWorker({ ...state.settings })
              ? PART_TIME
              : "",
            status: obj.status ?? "",
          });
        });
      }
      state.getUpcomingShifts.status = "fulfilled";
      if (upcomingShifts.length === 0) {
        // If there is no confirmed shifts then set can load more to false
        state.getUpcomingShifts.canLoadMore = false;
      } else {
        if (!updating) {
          state.getUpcomingShifts.canLoadMore = true;
        }
        // Sort based on start date
        upcomingShifts = upcomingShifts.sort((a: Shift, b: Shift) => {
          return (
            new Date(a.startDateTime).getTime() -
            new Date(b.startDateTime).getTime()
          );
        });
        // Append new shifts to existing one, overwrite in case it overlap.
        existingUpcomingShifts = overwriteShiftObjectsWithIds(
          existingUpcomingShifts,
          upcomingShifts
        );
        // Sort based on start date
        existingUpcomingShifts = existingUpcomingShifts.sort(
          (a: Shift, b: Shift) => {
            return (
              new Date(a.startDateTime).getTime() -
              new Date(b.startDateTime).getTime()
            );
          }
        );
        // Update shifts confirmed state replace with new data if updating
        state.upcomingShifts = updating
          ? upcomingShifts
          : existingUpcomingShifts;
      }
    });
    builder.addCase(getUpcomingShifts.rejected, (state, action: any) => {
      const error = Object.prototype.hasOwnProperty.call(
        action.payload?.error,
        "message"
      )
        ? action.payload?.error.message
        : action.payload?.error;
      if (error === "No data found") {
        state.getUpcomingShifts.status = "error";
        state.getUpcomingShifts.errorMessage =
          "There are no shifts available for you to sign up at this time. Please keep an eye out for new shifts to be posted.";
      } else {
        state.getUpcomingShifts.status = "error";
        state.getUpcomingShifts.errorMessage =
          error ??
          "An error occurred. Please click the link in the sms / text message again.";
      }
    });
    builder.addCase(confirmShift.pending, (state) => {
      state.confirmShift.status = "pending";
    });
    builder.addCase(confirmShift.rejected, (state, action: any) => {
      const data = action.payload ?? {};
      const settings = { ...state.settings };
      state.confirmShift.status = "error";
      state.confirmShift.shifts = Object.prototype.hasOwnProperty.call(
        data,
        "shifts"
      )
        ? data.shifts
        : [];
      const maxWorkHours = Object.prototype.hasOwnProperty.call(
        data,
        "maxWorkHours"
      )
        ? data.maxWorkHours
        : 0;
      const startDateTime = Object.prototype.hasOwnProperty.call(
        data,
        "startDateTime"
      )
        ? data.startDateTime
        : "";
      const endDateTime = Object.prototype.hasOwnProperty.call(
        data,
        "endDateTime"
      )
        ? data.endDateTime
        : "";
      if (startDateTime !== "" && endDateTime !== "" && maxWorkHours !== 0) {
        state.confirmShift.weeklyHoursLimitMessage = getWeeklyHoursLimitMessage(
          settings,
          startDateTime,
          endDateTime
        );
      } else {
        state.confirmShift.weeklyHoursLimitMessage = "";
      }
      state.confirmShift.errorMessage = Object.prototype.hasOwnProperty.call(
        data,
        "message"
      )
        ? data.message
        : "An error occurred";
    });
    builder.addCase(confirmShift.fulfilled, (state, action) => {
      const data = action.payload as unknown as {
        shiftId: number;
        status: string;
        checkConsecutiveShiftSignup: boolean;
        startDateTime: string;
        endDateTime: string;
      };
      const index = state.upcomingShifts.findIndex(
        (el: Shift) => el.id === data.shiftId
      );
      if (index > -1) {
        state.upcomingShifts[index].status = "A";
      }
      state.confirmShift.status = "fulfilled";
      state.signupShiftDialog.isOpen = false;
    });

    builder.addCase(viewShift.pending, (state) => {
      state.viewShift.status = "pending";
      state.viewShift.errorMessage = "";
    });
    builder.addCase(viewShift.rejected, (state, action: any) => {
      const data = action.payload ?? {};
      state.viewShift.status = "error";
      state.viewShift.errorMessage = Object.prototype.hasOwnProperty.call(
        data,
        "message"
      )
        ? data.message
        : "An error occurred";
    });
    builder.addCase(viewShift.fulfilled, (state, action) => {
      state.viewShift.errorMessage = "";
      state.viewShift.status = "fulfilled";
      state.viewShift.viewedShifts = action.payload ?? [];
    });

    builder.addCase(declineShift.pending, (state) => {
      state.declineShift.status = "pending";
    });
    builder.addCase(declineShift.rejected, (state, action: any) => {
      state.declineShift.status = "error";
      state.declineShift.errorMessage = action.payload ?? "An error occurred";
    });
    builder.addCase(declineShift.fulfilled, (state, action) => {
      const shiftId: number = action.payload as unknown as number;

      const index = state.upcomingShifts.findIndex(
        (el: Shift) => el.id === shiftId
      );
      (state.upcomingShifts[index] as { id: number; status: string }).status =
        "D";
      state.declineShift.status = "idle";
      state.signupShiftDialog.isOpen = false;
    });
    builder.addCase(cancelShift.pending, (state, action) => {
      state.cancelShift.status = "pending";
    });
    builder.addCase(cancelShift.rejected, (state, action: any) => {
      state.cancelShift.status = "error";
      const errorMessage = "An error occurred";
      state.cancelShift.errorMessage = action.payload
        ? action.payload.message ?? errorMessage
        : errorMessage;
      state.cancelShift.response = undefined;
      state.cancelShift.type = "C";
      state.cancelShift.startDateTime = undefined;
      state.cancelShift.endDateTime = undefined;
    });
    builder.addCase(cancelShift.fulfilled, (state, action) => {
      try {
        const response = action.payload;
        if (Object.prototype.hasOwnProperty.call(response, "message")) {
          state.cancelShift.response = response;
          state.cancelShift.status = "error";
        } else {
          const shiftId = response.shiftId;
          const index = state.shiftsConfirmed.findIndex(
            (el) => el.id === shiftId
          );
          if (index > -1) {
            state.shiftsConfirmed[index].status = "C";
          }
          state.cancelShift.status = "fulfilled";
          state.cancelShift.type = response.status;
          state.cancelShift.startDateTime = response.startDateTime;
          state.cancelShift.endDateTime = response.endDateTime;
        }
      } catch (error) {
        state.cancelShift.status = "error";
        state.cancelShift.errorMessage = action.payload ?? "An error occurred";
        state.cancelShift.type = "C";
        state.cancelShift.response = undefined;
        state.cancelShift.startDateTime = undefined;
        state.cancelShift.endDateTime = undefined;
      }
    });
    // forceCancel
    builder.addCase(forceCancelShift.pending, (state) => {
      state.forceCancelShift.status = "pending";
    });
    builder.addCase(forceCancelShift.rejected, (state, action) => {
      state.forceCancelShift.status = "error";
      state.cancelShift.errorMessage =
        (action.payload as string) ?? "An error occurred";
    });
    builder.addCase(forceCancelShift.fulfilled, (state, action) => {
      try {
        const shiftId = action.payload.shiftId;
        const index = state.shiftsConfirmed.findIndex(
          (el) => el.id === shiftId
        );
        if (index > -1) {
          state.shiftsConfirmed[index].status = "C";
        }
        state.forceCancelShift.status = "idle";
        state.cancelShift.status = "fulfilled";
        state.cancelShift.type = "C";
        state.cancelShift.startDateTime = action.payload.startDateTime;
        state.cancelShift.endDateTime = action.payload.endDateTime;
      } catch (error) {
        state.cancelShift.status = "error";
        state.cancelShift.errorMessage = "An error occurred";
        state.cancelShift.type = "C";
        state.cancelShift.response = undefined;
        state.cancelShift.startDateTime = undefined;
        state.cancelShift.endDateTime = undefined;
      }
    });
    //  -- forceCancel end
    builder.addCase(getSkillLevels.pending, (state) => {
      state.getSkillLevels.status = "pending";
    });
    builder.addCase(getSkillLevels.rejected, (state, action: any) => {
      state.getSkillLevels.status = "error";
      const errorMessage = "An error occurred";
      state.getSkillLevels.errorMessage = action.payload
        ? action.payload.message ?? errorMessage
        : errorMessage;
    });
    builder.addCase(getSkillLevels.fulfilled, (state, action) => {
      const data = action.payload ?? [];
      let skillLevels = [];
      if (Object.prototype.hasOwnProperty.call(data, "level")) {
        skillLevels = data;
      } else if (data.length > 0) {
        // Getting companies array instead
        let selectedCompany = data[0];
        const fullTimeCompanies = data.filter(
          (company: { workerTypeName: string }) =>
            company.workerTypeName === "FullTime"
        );
        if (fullTimeCompanies) {
          if (fullTimeCompanies.length > 0) {
            selectedCompany = fullTimeCompanies[0];
          }
        }
        skillLevels = selectedCompany.skillLevels ?? [];
      }
      if (skillLevels.length > 0) {
        skillLevels = skillLevels.map((el: { id: number }) => ({
          ...el,
          levelId: el.id,
        }));
      }
      state.skillLevels = skillLevels;
      state.getSkillLevels.status = "fulfilled";
      state.getSkillLevels.errorMessage = "";
    });
    builder.addCase(getProfile.pending, (state) => {
      state.workerProfile.status = "pending";
    });
    builder.addCase(getProfile.rejected, (state, action: any) => {
      state.workerProfile.status = "error";
      const errorMessage = "An error occurred";
      state.workerProfile.errorMessage = action.payload
        ? action.payload.message ?? errorMessage
        : errorMessage;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.workerProfile.data = action.payload;
      state.workerProfile.status = "fulfilled";
    });
    builder.addCase(getRegularShifts.pending, (state) => {
      state.getRegularShifts.status = "pending";
    });
    builder.addCase(getRegularShifts.rejected, (state, action: any) => {
      state.getRegularShifts.status = "error";
      state.getRegularShifts.errorMessage =
        action.payload ?? "An error occurred";
    });
    builder.addCase(getRegularShifts.fulfilled, (state, action) => {
      state.getRegularShifts.status = "fulfilled";
      state.getRegularShifts.errorMessage = "";
      const data =
        (action.payload as unknown as { shifts: Shift[]; updating: boolean })
          .shifts ?? [];
      const updating = (
        action.payload as unknown as { shifts: Shift[]; updating: boolean }
      ).updating;
      let regularShifts: Shift[] = [];
      let existingRegularShifts: Shift[] = [...state.regularShifts];
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          regularShifts.push({
            ...obj,
            shiftType: REGULAR,
            status: "",
          });
        });
      }
      if (regularShifts.length === 0) {
        // If there is no regular shifts then set can load more to false
        state.getRegularShifts.canLoadMore = false;
      } else {
        if (!updating) {
          state.getRegularShifts.canLoadMore = true;
        }
        // Sort regular shifts
        regularShifts = regularShifts.sort((a, b) => {
          return (
            new Date(a.startDateTime).getTime() -
            new Date(b.startDateTime).getTime()
          );
        });
        // Append new regular shift
        existingRegularShifts = existingRegularShifts.concat(regularShifts);
        // Remove duplicate shifts
        existingRegularShifts = existingRegularShifts.filter(
          (v, i, a) => a.findIndex((v2) => v2.ftShiftId === v.ftShiftId) === i
        );
        // Sort based on start date
        existingRegularShifts = existingRegularShifts.sort((a, b) => {
          return (
            new Date(a.startDateTime).getTime() -
            new Date(b.startDateTime).getTime()
          );
        });
        // Update regular shifts state
        state.regularShifts = updating ? regularShifts : existingRegularShifts;
      }
      state.getRegularShifts.status = "fulfilled";
    });
    builder.addCase(getCustomLogo.pending, (state) => {
      state.getCustomLogo.status = "pending";
    });
    builder.addCase(getCustomLogo.rejected, (state, action: any) => {
      state.getCustomLogo.status = "error";
      state.getCustomLogo.errorMessage = action.payload ?? "An error occurred";
    });
    builder.addCase(getCustomLogo.fulfilled, (state, action) => {
      state.getCustomLogo.status = "fulfilled";
      state.getCustomLogo.errorMessage = "";
      state.getCustomLogo.response = action.payload ?? []; // Do not use directly for logo
    });
    builder.addCase(getSettings.pending, (state) => {
      state.getSettings.status = "pending";
    });
    builder.addCase(getSettings.rejected, (state, action: any) => {
      // Logout if setting api failed
      state.authenticated = false;
      state.getPasscode.status = "idle";
      state.getPasscode.errorMessage = "";
      state.verifyPasscode.status = "idle";
      state.verifyPasscode.errorMessage = "";
      state.verifyPasscode.isLocked = false;
      state.getCustomLogo.status = "idle";
      state.getCustomLogo.errorMessage = "";
      state.getSettings.status = "idle";
      state.getSettings.errorMessage = "";
      state.getCustomLogo.response = [];
      state.getSettings.status = "idle";
      state.getSettings.errorMessage = "";
      state.customLogo = "";
      state.shiftsConfirmed = [];
      state.upcomingShifts = [];
      state.getConfirmedShifts.status = "idle";
      state.getConfirmedShifts.errorMessage = "";
      state.getConfirmedShifts.canLoadMore = true;
      state.getRegularShifts.status = "idle";
      state.getRegularShifts.errorMessage = "";
      state.getRegularShifts.canLoadMore = true;
      state.getUpcomingShifts.status = "idle";
      state.getUpcomingShifts.errorMessage = "";
      state.getUpcomingShifts.canLoadMore = true;
      localStorage.removeItem("worker-token");
      localStorage.removeItem("worker-refreshToken");
      localStorage.removeItem("worker-details");
      state.Refresh.status = "idle";
      state.Refresh.errorMessage = "";
    });

    builder.addCase(getAcknowledgements.pending, (state) => {
      state.getAcknowledgements.status = "pending";
      state.getAcknowledgements.errorMessage = "";
    });
    builder.addCase(getAcknowledgements.rejected, (state, action) => {
      state.getAcknowledgements.status = "error";
      state.getAcknowledgements.errorMessage =
        "Unexpected response from server. Please contact support.";
    });
    builder.addCase(getAcknowledgements.fulfilled, (state, action) => {
      try {
        state.getAcknowledgements.status = "fulfilled";
        state.getAcknowledgements.errorMessage = "";
        /* Constants info
                    p : Present
                    a : Absent
                    t : Tardy
                    e : Excused Absent
                    x : Excused Tardy
                    * */
        const getAcknowledgementsResponseArray = Array.isArray(action.payload)
          ? action.payload
          : [];
        const allAcknowledgements: { attendStatus: string }[] = [];
        getAcknowledgementsResponseArray.forEach(
          (ack: { attendStatus: any }) => {
            switch (ack.attendStatus) {
              case "p":
                allAcknowledgements.push({ ...ack, attendStatus: PRESENT });
                break;
              case "a":
                allAcknowledgements.push({ ...ack, attendStatus: ABSENT });
                break;
              case "t":
                allAcknowledgements.push({ ...ack, attendStatus: TARDY });
                break;
              case "e":
                allAcknowledgements.push({
                  ...ack,
                  attendStatus: EXCUSED_ABSENT,
                });
                break;
              case "x":
                allAcknowledgements.push({
                  ...ack,
                  attendStatus: EXCUSED_TARDY,
                });
                break;
              default:
                break;
            }
          }
        );
        state.acknowledgements = allAcknowledgements ?? [];
      } catch (e) {
        // Do nothing
        state.getAcknowledgements.status = "error";
        state.getAcknowledgements.errorMessage =
          "Problem parsing acknowledgements.";
      }
    });

    builder.addCase(getSettings.fulfilled, (state: any, action: any) => {
      state.getSettings.status = "fulfilled";
      state.getSettings.errorMessage = "";
      state.settings.smsNotifications = action.payload.smsNotifications;
      state.settings.smsNotificationsId = action.payload.smsNotificationsId;
      state.settings.smsDailyShiftReminders =
        action.payload.smsDailyShiftReminders;
      state.settings.smsDailyShiftRemindersId =
        action.payload.smsDailyShiftRemindersId;
      state.settings.displayAvailability = action.payload.displayAvailability;
      state.settings.displayAvailabilityId =
        action.payload.displayAvailabilityId;
      state.settings.showScore = action.payload.showScore;
      state.settings.showScoreId = action.payload.showScoreId;
      state.settings.showSkill = action.payload.showSkill;
      state.settings.showSkillId = action.payload.showSkillId;
      state.settings.initialized = action.payload.initialized;
      state.settings.autoAccept = action.payload.autoAccept;
      state.settings.autoAcceptId = action.payload.autoAcceptId;
      state.settings.dailyMaxHours = action.payload.dailyMaxHours;
      state.settings.dailyMaxHoursId = action.payload.dailyMaxHoursId;
      state.settings.workerAttendanceAcknowledgement =
        action.payload.workerAttendanceAcknowledgement;
      state.settings.workerAttendanceAcknowledgementId =
        action.payload.workerAttendanceAcknowledgementId;
      state.settings.showShiftAutoAccept = action.payload.showShiftAutoAccept;
      state.settings.showShiftAutoAcceptId =
        action.payload.showShiftAutoAcceptId;
      state.settings.fullTimeShiftSwapCancelEnabled =
        action.payload.fullTimeShiftSwapCancelEnabled;
      state.settings.timeOffEnabled = action.payload.timeOffEnabled;
      state.settings.workerProfileInfo = action.payload.workerProfileInfo;
      state.settings.workerProfileInfoId = action.payload.workerProfileInfoId;
      state.workerType = isFullTimeWorker(action.payload)
        ? FULL_TIME_WORKER
        : isPartTimeWorker(action.payload)
        ? PART_TIME_WORKER
        : FLEX_WORKER;
      state.settings.companyId = action.payload.companyId;
      state.settings.customizedBrandingEnabled =
        action.payload.customizedBrandingEnabled;
      const selectedCompany = getSelectedCompany(action.payload);
      if (
        Object.prototype.hasOwnProperty.call(selectedCompany, "maxWorkHours") &&
        Object.prototype.hasOwnProperty.call(
          selectedCompany,
          "remainingWorkHours"
        ) &&
        Object.prototype.hasOwnProperty.call(
          selectedCompany,
          "workerTypeName"
        ) &&
        Object.prototype.hasOwnProperty.call(selectedCompany, "workerTypeText")
      ) {
        state.settings.maxWorkHours = selectedCompany.maxWorkHours;
        state.settings.remainingWorkHours = selectedCompany.remainingWorkHours;
        state.settings.workerTypeText = selectedCompany.workerTypeText;
        state.settings.workerTypeName = selectedCompany.workerTypeName;
      }
      if (
        Object.prototype.hasOwnProperty.call(
          selectedCompany,
          "showWeeklyHoursLimitNoticeOn"
        )
      ) {
        state.getSettings.showWeeklyHoursLimitNoticeOn =
          selectedCompany.showWeeklyHoursLimitNoticeOn;
      }

      // Heap additional information added
      try {
        if (
          action.payload &&
          (window as any).heap &&
          typeof (window as any).heap.identify === "function"
        ) {
          (window as any).heap.identify(selectedCompany.workerId);
          if (
            Object.prototype.hasOwnProperty.call(selectedCompany, "workerId") &&
            Object.prototype.hasOwnProperty.call(
              selectedCompany,
              "companyName"
            ) &&
            Object.prototype.hasOwnProperty.call(
              selectedCompany,
              "companyId"
            ) &&
            Object.prototype.hasOwnProperty.call(
              selectedCompany,
              "workerTypeText"
            )
          ) {
            (window as any).heap.addUserProperties({
              companyName: selectedCompany.companyName,
              companyId: selectedCompany.companyId,
              workerType: selectedCompany.workerTypeText,
              fistName: state.workerData.firstName,
              lastName: state.workerData.lastName,
            });
          }
        }
      } catch (e) {
        console.log("Heap failure", e);
      }
    });
  },
});

export const {
  setCustomLogo,
  setTabToViewShifts,
  setTabToShiftSignup,
  setTab,
  resetCancelShift,
  resetConfirmShift,
  resetGetRegularShifts,
  resetGetSettings,
  openSignupShiftDialog,
  closeSignupShiftDialog,
  setWorkerId,
  setWorker,
  closeViewShiftDialog,
  closeViewRegularShiftDialog,
  openViewShiftDialog,
  openViewRegularShiftDialog,
  logout,
  resetAcknowledgements,
} = WebWorkerSlice.actions;

export default WebWorkerSlice.reducer;
